<template>
    <div class="mt-3 pt-3">
        <b-card>
            <app-view-table :title="trans('users',280)" customClass="app-local-table-min-height" :isTitle="true" :isSearch="true"  :loading="loading" :customColumns="customColumns" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="user_status" :isFilterOptions="true"  resizeableColumns resizeMinWidth>
                
                <template v-slot:person_name="cell">
                    {{cell.data.item.last_name + ', ' + cell.data.item.first_name + (cell.data.item.inbetween_name !== null ? ' ' + cell.data.item.inbetween_name : '')}}
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="editReminders(cell.data.item)" v-if="id_user_const_role == 5" >{{trans('cp-edit-reminders',290)}}</b-dropdown-item>
                    <b-dropdown-item @click="removeContact(cell.data.item)">{{trans('remove-contact',282)}}</b-dropdown-item>
                    <b-dropdown-divider v-if="cell.data.item.id_user_portal != null && cell.data.item.blocked == 0"></b-dropdown-divider>
                    <b-dropdown-item @click="sendResetLink(cell.data.item)" v-if="cell.data.item.id_user_portal != null && cell.data.item.blocked == 0" >{{trans('pl-resend-registration-email',295)}}</b-dropdown-item>
                </template>

                <template #buttons v-if="user_role == 5">
                    <app-button type="primary" @click="addContact">{{trans('pl-add-user',295)}}</app-button>
                </template>

            </app-view-table>

            <users-contact-add v-if="mContactAdd.show" :show.sync="mContactAdd.show" :result.sync="mContactAdd.result" :args.sync="mContactAdd.args" :return.sync="items" />
            <users-reminder-edit v-if="mRemindersEdit.show" :show.sync="mRemindersEdit.show" :result.sync="mRemindersEdit.result" :args.sync="mRemindersEdit.args" :return.sync="items" />
        </b-card>
    </div>
</template>

<script>
import UsersContactAdd from './UsersContactAdd.vue';
import UsersReminderEdit from './UsersReminderEdit.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";

export default {

    components: {
        UsersContactAdd,
        UsersReminderEdit
    },

    props: {
        idsContactDataMain: {},
        idClientDataMain: { },     
        listHeight: {type: String, default: "240px"},
        show: {type: Boolean},
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
       
        cIdsContactDataMain: {
            get() { return this.idsContactDataMain },
            set(value) {this.$emit('update:ids_contact_data_main', value) }
        },
    
    },

    data(){
        return{
            user_role: JSON.parse(localStorage.getItem('user-portal')).id_user_const_role,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,
                },
                filtering: {                        
                        portal_role: { type: 'list', changed: 0, values: [],customList: [{ value: 5, name: "Admin" }, { value: 6, name: "User" },], parameters: null, widthClass:'app-width-min-200'}
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0
            },
            
            user_status: [
                { value: 1, name: this.trans('pl-active',295) },
                { value: 2, name: this.trans('pl-inactive',295) }
            ],

            total: null,

            customColumns: ["person_name"],

            mContactAdd: { show: false, args: {
                            filters: null
                        }, result: 0, return: null },
            mRemindersEdit: { show: false, args: {
                filters: null,
                weekly_reminder: null,
                id_contact_data_main: null
            }, result: 0, return: null },
            fields: [
                { key: "person_name", label: this.trans('sh-name',292), thStyle: {  "padding-right":"25px",  width: "100%" } , visible: true },
                { key: "email", label: this.trans('email',283), thStyle: { "padding-right":"25px", "min-width": "300px", "max-width": "300px" }, visible: true},
                { key: "function", label: this.trans('sh-function',292), thStyle: { "padding-right":"25px", "min-width": "150px", "max-width": "150px" }, visible: true},
                { key: "portal_role", label: this.trans('portal-roll',280), thStyle: {  "padding-right":"25px", "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "portal_role" },

            ],

            items:[],
            loading: false,

            mRegisterPortal: { show: false, args: {id_client_data_main: null, id_contact_data_main: null, filters: null}, result: 0, return: null },
            mChangeRolePortal: { show: false, args: {id_client_data_main: null, id_user_portal: null, filters: null}, result: 0, return: null },

            alertClass: new AppAlerts(),
            id_user_const_role: JSON.parse(localStorage.getItem('user-portal')).id_user_const_role,

        }
    },

    methods: {

        getList(){
            this.loading = true;
            axios
                .post("users/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        removeContact(item){
            var qustion = "";
            if(item.total_contact_client == 0 && item.total_contact_department == 0 && item.total_contact_location == 0){
                qustion = "Weet je zeker dat je <b>" + item.first_name + ' ' + item.last_name + "</b> wilt verwijderen?";
            }else{
                qustion = "De contactpersoon is gekoppeld aan " + (item.total_contact_client > 0 ? ("<b>"+item.total_contact_client + "</b> klant, ") : "") 
                + (item.total_contact_location > 0 ? "<b>"+item.total_contact_location + "</b> vestiging, " : "") 
                + (item.total_contact_department > 0 ? "<b>"+item.total_contact_department + "</b> afdeling." : "") 
                + "Weet je zeker dat?"; 
            }

            this.alertClass.openAlertConfirmation(qustion).then(res => {
                if(res == true){
                        
                        this.loading = true;

                        axios
                            .post("users/removeContact", {
                                filters: JSON.stringify(this.filters),
                                contact_data: JSON.stringify(item),
                            })
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }

                });

        },

        sendResetLink(item){
                this.alertClass.openAlertConfirmation('Wil je echt het inschrijvingsformulier opnieuw sturen naar <b> '+ item.first_name + ' ' + item.last_name).then(res => {
                if(res == true){  
                    this.loading = true;

                    axios
                        .post("users/sendResetLink", {
                            contact_data: JSON.stringify(item),
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },

        addContact(){
            this.mContactAdd.args.filters = this.filters;
            this.mContactAdd.show = true;
        },

        editReminders(item){
            this.mRemindersEdit.args.filters = this.filters;
            this.mRemindersEdit.args.weekly_reminder = item.weekly_reminder;
            this.mRemindersEdit.args.id_contact_data_main = item.id_contact_data_main;
            this.mRemindersEdit.show = true;
        },

        onCreateThis() { 

            this.getList();
            this.fields.filter(field => field.visible);            

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });        

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.portal_role.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },        
    },
}
</script>

<style scoped>
</style>