<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-edit-reminders',290)">
        
        <ValidationObserver ref="form" >    

            <app-row-left-label :label="trans('cp-send-weekly-reminder',290)">
                <app-radio-group v-model="cArgs.weekly_reminder" :options="question_options" />
            </app-row-left-label>

        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel', 282)}}</app-button>
            <app-button @click="remindersEdit" :loading="loading" type="primary">
                {{trans('save',282)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    data() {
        return {
            loading: false,
            question_options: [
                { value: 1, text: this.trans('yes-option',280) },
                { value: 0, text: this.trans('no-option',280) },
            ],
        };
    },

    created(){

    },

     computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    methods: {

        remindersEdit() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                    this.loading = true;

                    axios
                        .post("users/remindersEdit", {
                            id_contact_data_main: this.args.id_contact_data_main,
                            weekly_reminder: this.cArgs.weekly_reminder,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            this.$emit('update:return', res.data.items);
                            this.$emit('update:result', 1);
                            this.$emit("update:show", false);
                           
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            });
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

