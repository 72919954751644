<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('pl-add-user',295)">
        
        <ValidationObserver ref="form" >    

            <app-row-left-label :label="trans('u-first-name', 290)">
                <app-input v-model="contact_data.first_name" :validatorName="trans('u-first-name', 290)" validatorRules="required|max:20" mask="lettersE1-20" />
            </app-row-left-label>

            <app-row-left-label :label="trans('u-surname', 290)" :slots="[4,8]">
                    <template v-slot:col-1>
                        <app-input v-model="contact_data.inbetween_name" :placeholder="trans('u-insertion', 290)" mask="lettersE1-10"/>
                    </template>
                     <template v-slot:col-2>
                        <app-input v-model="contact_data.last_name" :placeholder="trans('u-surname', 290)" :validatorName="trans('u-surname', 290)" validatorRules="required" mask="lettersE1-30" />
                    </template>
            </app-row-left-label>
            
            <app-row-left-label :label="trans('u-function', 290)">
                <app-input v-model="contact_data.function" :validatorName="trans('u-function', 290)" validatorRules="required|max:30" mask="lettersE1-30" />
            </app-row-left-label>

            <app-row-left-label :label="trans('u-phone-number', 290)">
                <app-phone :prefix.sync="contact_data.phone_prefix" :phone.sync="contact_data.phone_number" />
            </app-row-left-label>

            <app-row-left-label :label="trans('u-email', 290)">
                <app-email :email.sync="contact_data.email" all ref="email_control" :validatorName="trans('u-email', 290)" validatorRules="required|email" />
            </app-row-left-label>

            <app-row-left-label :label="trans('u-portal-role', 290)" >
                <app-radio-group v-model="contact_data.id_user_const_role" :options="opt" :validatorName="trans('u-portal-role', 290)" validatorRules="required" />
            </app-row-left-label>

            <app-row-left-label :label="trans('cp-send-weekly-reminder',290)" v-if="id_user_const_role == 5">
                <app-radio-group v-model="contact_data.weekly_reminder" :options="question_options" />
            </app-row-left-label>

        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel', 282)}}</app-button>
            <app-button @click="contactAdd" :loading="loading" type="primary">
                {{trans('add', 290)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        ValidationObserver,
        AppPhone,
        AppEmail
    },

    props: ["show", "result", "args", "return"],

    data() {
        return {
            id_user_const_role: JSON.parse(localStorage.getItem('user-portal')).id_user_const_role,
            loading: false,
            contact_data:{
                id: null,
                function:"",
                first_name: "",
                inbetween_name:"",
                last_name: "",
                phone_prefix: '+31',
                phone_number: null,
                email: "",
                id_user_const_role: null,
                weekly_reminder: 1
            },
            opt: [ { value: 5, text: "Admin" }, { value: 6, text: "Gebruiker" }],
            question_options: [
                { value: 1, text: this.trans('yes-option',280) },
                { value: 0, text: this.trans('no-option',280) },
            ],
        };
    },

    created(){

    },

     computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    methods: {

        async contactAdd() {
            await this.$refs.email_control.checkEmailExist();
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                    this.loading = true;

                    axios
                        .post("users/addContact", {
                            contact_data: JSON.stringify(this.contact_data),
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            this.$emit('update:return', res.data.items);
                            this.$emit('update:result', 1);
                            this.$emit("update:show", false);
                           
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            });
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

